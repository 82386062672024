<template>
  <div class="policy">
    <div name="termly-embed" data-id="a80a6f41-fd03-4469-b589-5bd790e52d6f" data-type="iframe"></div>
  </div>
</template>

<script type="text/javascript">
import { getUser } from '@/api/users'

(function (d, s, id) {
  const tjs = d.getElementsByTagName(s)[0]
  if (d.getElementById(id)) return
  const js = d.createElement(s)
  js.id = id
  js.src = 'https://app.termly.io/embed-policy.min.js'
  tjs.parentNode.insertBefore(js, tjs)
}(document, 'script', 'termly-jssdk'))

export default {
  methods: {
    async fetchUser () {
      await this.$store.dispatch('user/setUser', await getUser())
    }
  },
  computed: {
    computedUser () {
      return (this.$store.state.user.user && this.$store.state.user.user.id) ? this.$store.state.user.user : null
    }
  },
  beforeMount () {
    if (!this.computedUser) { this.fetchUser() }
  }
}
</script>
